body {
    background: hsla(210, 20%, 90%, 1);
    padding: 2em 0;
    line-height: 1.6;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
  }
  html{
    background-color:antiquewhite;
  }
  
  /*hsla(210, 20%, 90%, 1);*/